<template>
  <section class="about">
    <secondary-nav></secondary-nav>

    <div class="su-body">
      <div class="about-us-section container">
        <div class="row">
          <div class="col-1 void-column"></div>

          <div class="col-12 col-md-11">
            <div class="row">
              <div class="col-12">
                <h1>Our partners</h1>
              </div>

              <div class="partner-content col-12 col-md-6 d-flex align-items-center flex-column">
                <div class="partner-card msc"></div>
              </div>

              <div class="partner-content col-12 col-md-6 d-flex align-items-center flex-column">
                <div class="partner-card artled"></div>
              </div>

              <div class="partner-content col-12 col-md-6 d-flex align-items-center flex-column">
                <div class="partner-card beautiful-card flex-column">
                  <a href="https://beautifullife.cc/">
                    <div>Beautiful Life</div>
                    <div style="font-weight: 200; font-size: 23.6px">Creative Studio</div>
                  </a>
                </div>
              </div>

              <div class="partner-content col-12 col-md-6 d-flex align-items-center flex-column">
                <div class="partner-card nuthouse"></div>
              </div>

              <div class="partner-content col-12 col-md-6 d-flex align-items-center flex-column">
                <div class="partner-card dvstudios"></div>
              </div>

              <div class="partner-content col-12 col-md-6 d-flex align-items-center flex-column">
                <div class="partner-card alice"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <footer-row></footer-row>
    </div>
  </section>
</template>

<script lang="js">
import Vue from 'vue';
import SecondaryNavBar from "@/components/secondary-nav";

export default Vue.extend({
  name: 'about',
  metaInfo: {
    title: 'About',
  },
  components: {
    'secondary-nav' : SecondaryNavBar,
  },
});
</script>
