
  import Vue from 'vue';
  
  export default Vue.extend({
    name: 'FormPage',
    data(): { showForm: boolean } {
      return {
        showForm: true
      };
    },
    methods: {
      closeModal(): void {
        this.showForm = false;
        this.$nextTick(() => {
          this.$router.go(-1);
        });
      },
      handleIframeMessage(event: MessageEvent): void {
        if (event.origin === 'https://forms.monday.com') {
          // Handle iframe events
        }
      }
    },
    mounted(): void {
      window.addEventListener('message', this.handleIframeMessage);
    },
    beforeDestroy(): void {
      window.removeEventListener('message', this.handleIframeMessage);
    }
  });
  